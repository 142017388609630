import { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";

import {
  Box,
  Stack,
  HStack,
  Center,
  Heading,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
} from "@chakra-ui/react";

import MainLayout from "../../components/Layout/MainLayout";
import { fetchPayments } from "../../api/paymentEndpoints";
import UserContext from "../../contexts/UserContext";
import { IBusinessAccount, IPayment } from "../../interfaces";
import { fetchBusinessAccounts } from "../../api/businessEndpoints";

interface FuelManagerPageProps {}

const FuelManagerPage: FC<FuelManagerPageProps> = () => {
  const { token } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState<IPayment[]>([]);
  const [businessAccounts, setBusinessAccounts] = useState<IBusinessAccount[]>(
    []
  );

  const [filterBusiness, setFilterBusiness] = useState(0);
  const [filterDate, setFilterDate] = useState("today");

  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  useEffect(() => {
    fetchPayments({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          setPayments(response.data.payments);
        }

        const interval = setInterval(() => {
          setLoading(false);
        }, 1000);
        return () => clearInterval(interval);
      })
      .catch(console.log);

    fetchBusinessAccounts({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          setBusinessAccounts(response.data.business_accounts);
        }
      })
      .catch(console.log);
  }, [token]);

  const handleFilterBusiness = (payment: IPayment) => {
    if (filterBusiness === 0) {
      return true;
    }

    if (!payment.account) {
      return false;
    }

    if (filterBusiness !== 0 && payment.account.account_id !== filterBusiness) {
      return false;
    }

    return true;
  };

  const handleFilterDate = (payment: IPayment) => {
    const d1 = DateTime.fromISO((payment.date_created || "").replace(" ", "T"));

    if (filterDate === "today") {
      const d2 = DateTime.now();

      if (d2.hasSame(d1, "day")) {
        return true;
      } else {
        return false;
      }
    }

    if (filterDate === "yesterday") {
      const d2 = DateTime.now().minus({ days: 1 });

      if (d2.hasSame(d1, "day")) {
        return true;
      } else {
        return false;
      }
    }

    if (filterDate === "this-week") {
      const d2 = DateTime.now();

      if (d1.weekNumber === d2.weekNumber && d1.year === d2.year) {
        return true;
      } else {
        return false;
      }
    }

    if (filterDate === "last-week") {
      const d2 = DateTime.now();

      if (d1.weekNumber === d2.weekNumber - 1 && d1.year === d2.year) {
        return true;
      } else {
        return false;
      }
    }

    if (filterDate === "this-month") {
      const d2 = DateTime.now();

      if (d1.month === d2.month && d1.year === d2.year) {
        return true;
      } else {
        return false;
      }
    }

    if (filterDate === "last-month") {
      const d2 = DateTime.now();

      if (d1.month === d2.month - 1 && d1.year === d2.year) {
        return true;
      } else {
        return false;
      }
    }

    if (filterDate === "this-year") {
      const d2 = DateTime.now();

      if (d1.year === d2.year) {
        return true;
      } else {
        return false;
      }
    }

    if (filterDate === "custom") {
      const d2 = DateTime.fromISO(dateFrom);
      const d3 = DateTime.fromISO(dateTo);

      if (d1 > d2 && d1 < d3) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <MainLayout>
      <Stack
        w={"full"}
        mb={"20"}
        justify={"space-between"}
        align={{ base: "start", md: "center" }}
        direction={{ base: "column", md: "row" }}
      >
        <Heading>Payments</Heading>
      </Stack>

      {loading ? (
        <Center w={"full"} h={"75%"}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="dark"
            size="xl"
          />
        </Center>
      ) : (
        <Box>
          <HStack mb={"5"} justify={"space-between"} align={"end"}>
            <HStack justify={"space-between"} align={"center"} spacing={"8"}>
              <FormControl w={"250px"}>
                <Select
                  onChange={(e) => setFilterBusiness(parseInt(e.target.value))}
                >
                  <option value="0">All Business Accounts</option>
                  {businessAccounts.map((account) => {
                    return (
                      <option value={account.account_id}>
                        {account.listing.business_name}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </HStack>

            <HStack justify={"space-between"} align={"end"} spacing={"8"}>
              {filterDate === "custom" ? (
                <HStack>
                  <FormControl w={"220px"}>
                    <FormLabel>From:</FormLabel>
                    <Input
                      placeholder="Select Date and Time"
                      size="md"
                      type="datetime-local"
                      value={dateFrom}
                      onChange={(e) => setDateFrom(e.target.value)}
                    />
                  </FormControl>

                  <FormControl w={"220px"}>
                    <FormLabel>To:</FormLabel>
                    <Input
                      placeholder="Select Date and Time"
                      size="md"
                      type="datetime-local"
                      value={dateTo}
                      onChange={(e) => setDateTo(e.target.value)}
                    />
                  </FormControl>
                </HStack>
              ) : null}

              <FormControl w={"150px"}>
                <Select onChange={(e) => setFilterDate(e.target.value)}>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="this-week">This Week</option>
                  <option value="last-week">Last Week</option>
                  <option value="this-month">This Month</option>
                  <option value="last-month">Last Month</option>
                  <option value="this-year">This Year</option>
                  <option value="custom">Custom</option>
                </Select>
              </FormControl>
            </HStack>
          </HStack>

          <TableContainer bg={"white"} rounded={"8"}>
            <Table variant="simple">
              <TableCaption>
                Total Revenue: R
                {payments
                  .filter(handleFilterBusiness)
                  .filter(handleFilterDate)
                  .reduce((accumulator, object) => {
                    let amount: any =
                      Number(object?.payment_amount ?? 0) +
                      Number(object?.refer_amount ?? 0);

                    return accumulator + parseFloat(amount);
                  }, 0)
                  .toFixed(2)}
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>Business Name</Th>
                  <Th>Account Holder</Th>
                  <Th>Hive Amount</Th>
                  <Th>Affiliate Amount</Th>
                  <Th>Subscription Amount</Th>
                  <Th>Date</Th>
                  <Th>Linked Affiliate</Th>
                </Tr>
              </Thead>
              <Tbody>
                {payments
                  .filter(handleFilterBusiness)
                  .filter(handleFilterDate)
                  .map((payment) => {
                    let amount: any =
                      Number(payment?.payment_amount ?? 0) +
                      Number(payment?.refer_amount ?? 0);

                    return (
                      <Tr>
                        <Td>
                          {payment.account && payment.account.account_id && (
                            <Link
                              to={`/accounts/${payment.account.account_id}/view`}
                            >
                              {payment.listing.business_name}
                            </Link>
                          )}
                        </Td>
                        <Td>{payment.account && payment.account.full_name}</Td>
                        <Td>
                          R
                          {parseFloat(
                            payment.payment_amount as unknown as string
                          ).toFixed(2)}
                        </Td>
                        <Td>
                          R
                          {parseFloat(
                            payment.refer_amount as unknown as string
                          ).toFixed(2)}
                        </Td>

                        <Td>R{parseFloat(amount).toFixed(2)}</Td>

                        <Td>{payment.date_created}</Td>
                        <Td>
                          <Link
                            to={`/affiliates/${payment.affiliate?.affiliate_id}/view`}
                          >
                            {payment.affiliate?.full_name}
                          </Link>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </MainLayout>
  );
};

export default FuelManagerPage;
